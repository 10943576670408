$m0: 0;
$m1: 0.125rem;
$m2: 0.25rem;
$m3: 0.375rem;
$m4: 0.5rem;
$m5: 0.625rem;
$m6: 0.75rem;
$m7: 0.875rem;
$m8: 1rem;
$m9: 1.125rem;

.mt-0{
  margin-top: $m0!important;
}

.m-1 {
  margin: $m1;
}

.mt-1 {
  margin-top: $m1;
}

.mb-1 {
  margin-bottom: $m1;
}

.mr-1 {
  margin-right: $m1;
}

.ml-1 {
  margin-left: $m1;
}

.m-2 {
  margin: $m2;
}

.mt-2 {
  margin-top: $m2;
}

.mb-2 {
  margin-bottom: $m2;
}

.mr-2 {
  margin-right: $m2 !important;
}

.ml-2 {
  margin-left: $m2;
}

.m-3 {
  margin: $m3;
}

.mt-3 {
  margin-top: $m3;
}

.mb-3 {
  margin-bottom: $m3;
}

.mr-3 {
  margin-right: $m3;
}

.ml-3 {
  margin-left: $m3;
}

.m-4 {
  margin: $m4;
}

.mt-4 {
  margin-top: $m4!important;
}

.mb-4 {
  margin-bottom: $m4;
}

.mr-4 {
  margin-right: $m4 !important;
}

.ml-4 {
  margin-left: $m4 !important;
}

.m-5 {
  margin: $m5;
}

.mt-5 {
  margin-top: $m5;
}

.mb-5 {
  margin-bottom: $m5;
}

.mr-5 {
  margin-right: $m5 !important;
}

.ml-5 {
  margin-left: $m5;
}

.m-6 {
  margin: $m6;
}

.mt-6 {
  margin-top: $m6;
}

.mb-6 {
  margin-bottom: $m6;
}

.mr-6 {
  margin-right: $m6;
}

.ml-6 {
  margin-left: $m6 !important;
}

.m-7 {
  margin: $m7;
}

.mt-7 {
  margin-top: $m7;
}

.mb-7 {
  margin-bottom: $m7;
}

.mr-7 {
  margin-right: $m7;
}

.ml-7 {
  margin-left: $m7!important;
}

.m-8 {
  margin: $m8;
}

.mt-8 {
  margin-top: $m8;
}

.mb-8 {
  margin-bottom: $m8;
}

.mr-8 {
  margin-right: $m8 !important;
}

.ml-8 {
  margin-left: $m8 !important;
}

.m-9 {
  margin: $m9;
}

.mt-9 {
  margin-top: $m9;
}

.mb-9 {
  margin-bottom: $m9;
}

.mr-9 {
  margin-right: $m9 !important;
}

.ml-9 {
  margin-left: $m9 !important;
}
