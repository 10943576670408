.button {
  min-width: 6rem;
  height: 2.5rem;
  box-sizing: border-box;
  color: rgba($color: #000000, $alpha: 0.65);
  background-color: #ffffff;
  font-size: 1rem;
  cursor: pointer;
  transition: ease 0.25s;
  white-space: nowrap;
  align-self: flex-start;
}

.button:hover {
  background-color: rgba($color: #000000, $alpha: 0.05);
}

.button:focus {
  box-shadow: 0 0 0 2px rgba($color: #000000, $alpha: 0.1);
}

.button:disabled {
  background-color: #e9e9e9 !important;
  color: #ffffff !important;
  border-color: #e9e9e9 !important;
}

.button:active {
  background-color: #ffffff;
}

.button.outline {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.button.outline.blue {
  border-color: #1890ff;
  color: #1890ff;
}

.button.xs {
  min-width: 4.0625rem;
  font-size: 0.875rem;
  height: 2rem;
}

.button.sm {
  min-width: 4.625rem;
  font-size: 0.875rem;
  height: 2rem;
}

.button.md {
  min-width: 5rem;
}

.button.nofill.blue {
  background-color: #ffffff;
  color: #1890ff;
  border: 1px solid #1890ff;
  border-radius: 4px;
}

.button.nofill.blue:hover {
  color: rgba($color: #1890ff, $alpha: 0.85);
  border-color: rgba($color: #1890ff, $alpha: 0.85);
}

.button.fill.blue {
  background-color: #1890ff;
  color: #ffffff;
  border: 1px solid #1890ff;
  border-radius: 4px;
}

.button.fill.blue:hover {
  background-color: rgba($color: #1890ff, $alpha: 0.85);
}

.button.fill.blue:active {
  background-color: #1890ff;
}

.button.red:focus {
  box-shadow: 0 0 0 2px rgba($color: #ff4d4f, $alpha: 0.35);
}

.button.fill.red {
  background-color: #ff4d4f;
  color: #ffffff;
  border: 1px solid #ff4d4f;
  border-radius: 4px;
}

.button.fill.red:hover {
  background-color: rgba($color: #ff4d4f, $alpha: 0.85);
}

.button.fill.red:active {
  background-color: #ff4d4f;
}

.button.red:focus {
  box-shadow: 0 0 0 2px rgba($color: #ff4d4f, $alpha: 0.35);
}

.icon-button {
  min-width: 4rem;
  height: 4rem;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 0.5rem;
  border: none;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: ease 0.25s;
}

.icon-button > p {
  font-size: 0.75rem;
}

.icon-button > i {
  min-width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
}

.icon-button.orange {
  background-color: #ffc069;
  color: #ffffff;
}

.icon-button.orange:hover {
  background-color: rgba($color: #ffc069, $alpha: 0.85);
}

.icon-button.orange:active {
  background-color: #ffc069;
}

.icon-button.orange:focus {
  box-shadow: 0 0 0 2px rgba($color: #ffc069, $alpha: 0.35);
}

.icon-button.yellow {
  background-color: #ffe7ba;
  color: #fa8c16;
}

.icon-button.yellow:hover {
  background-color: rgba($color: #ffe7ba, $alpha: 0.85);
}

.icon-button.yellow:active {
  background-color: #ffe7ba;
}

.icon-button.yellow:focus {
  box-shadow: 0 0 0 2px rgba($color: #ffe7ba, $alpha: 0.35);
}

.icon-button.red {
  background-color: #ff7875;
  color: #ffffff;
}

.icon-button.red:hover {
  background-color: #ef6865;
}

.icon-button.red:active {
  background-color: #ff7875;
}

.icon-button.red:focus {
  box-shadow: 0 0 0 2px rgba($color: #ff7875, $alpha: 0.5);
}