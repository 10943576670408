.status {
  display: inline-flex;
  flex-direction: row;
  box-sizing: border-box;
  height: 1.5rem;
  padding: 0 0.5rem 0 0.5rem;
  border-radius: 4px;
  align-self: flex-start;
  align-items: center;
}

.status > p {
  font-size: 0.75rem;
  line-height: 0.75rem;
}

.status > i {
  cursor: pointer;
  font-size: 1rem;
}

.status.orange, .status.yellow {
  background-color: #ffe7ba;
  border: 1px solid #ffa940;
  color: #fa8c16;
}

.status.blue {
  color: #2f54eb;
  border: 1px solid #2f54eb;
  background-color: #d6e4ff;
}

.status.green {
  background-color: #d9f7be;
  color: #389e0d;
  border: 1px solid #52c41a;
}

.status.grey {
  background-color: #d9d9d9;
  border: 1px solid #bfbfbf;
  color: #919191;
}

.status.red{
  border: 1px solid #f5222d;
  background-color: #ffccc7;
  color: #cf1322;
}

.health-status {
  box-sizing: border-box;
  height: 1.5rem;
  color: #f5222d;
  background-color: #fff1f0;
  border-radius: 0.25rem;
  padding: 0 0.5rem;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.health-status > p {
  font-size: 0.875rem;
}

.cell-group-status {
  box-sizing: border-box;
  height: 1.5rem;
  color: #2276f5;
  background-color: #d5eafd;
  border-radius: 0.25rem;
  padding: 0 0.5rem;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.cell-group-status > p {
  font-size: 0.875rem;
}

.living-status {
  box-sizing: border-box;
  height: 1.5rem;
  color: #fa8c16;
  background-color: #fff2d9;
  border-radius: 0.25rem;
  padding: 0 0.5rem;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.living-status > p {
  font-size: 0.875rem;
}

.announcement-status {
  align-self: center;
  flex: 0 0;
}

.stage-container {
  flex: 1 0;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.stage {
  width: 2rem;
  height: 2rem;
  border: 1px solid rgba($color: #000000, $alpha: 0.15);
  color: rgba($color: #000000, $alpha: 0.15);
  border-radius: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.stage.red {
  background-color: #e05454 !important;
  border-color: #e05454 !important;
  color: #ffffff !important;
}

.stage.blue {
  background-color: #1890ff;
  border-color: #1890ff;
  color: #ffffff;
}

.stage.material-icons-round {
  background-color: #ffffff;
  color: #1890ff;
}

.stage-box {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  flex: 1 0;
}

.stage-title {
  font-weight: bolder;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.stage-title > p {
  font-size: 1rem;
}

.stage-container:first-child {
  padding-left: 0;
}
