/* You can add global styles to this file, and also import other style files */
@import "styles/flex.scss";
@import "styles/text.scss";
@import "styles/margin.scss";
@import "styles/popup.scss";
@import "styles/input.scss";
@import "styles/select.scss";
@import "styles/icon.scss";
@import "styles/button.scss";
@import "styles/table.scss";
@import "styles/status.scss";
@import "styles/padding.scss";

html {
  font-size: 16px;
  font-family: PingFang HK;
}

* {
  font-family: PingFang HK;
}

body {
  margin: 0;
  height: 100vh;
}

p {
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

label {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

p:focus,
div:focus,
textarea:focus,
input:focus,
select:focus,
button:focus {
  outline: none;
}

textarea::placeholder,
input::placeholder {
  color: rgba($color: #000000, $alpha: 0.25);
}

select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.user-input-error > p {
  font-size: 0.75rem!important;
}

@media screen and (max-width: 1000px) {
  html {
    font-size: 10px;
  }

  textarea {
    font-size: 0.875rem;
  }
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/materialicons/v55/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2") format("woff2");
}
@font-face {
  font-family: "Material Icons Round";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/materialiconsround/v26/LDItaoyNOAY6Uewc665JcIzCKsKc_M9flwmPq_HTTw.woff2")
    format("woff2");
}
@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/materialiconsoutlined/v27/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUcel5euIg.woff2")
    format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: flex;
  align-items: center;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}

.material-icons-round {
  font-family: "Material Icons Round";
  font-weight: normal;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: flex;
  align-items: center;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}

.material-icons-outlined {
  font-family: "Material Icons Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: flex;
  align-items: center;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  animation: zoomIn 0.2s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

body::-webkit-scrollbar,
div::-webkit-scrollbar {
  display: none;
}

.page-title {
  font-size: 2.25rem;
  line-height: 2.625rem;
  font-weight: bolder;
}

.container {
  background-color: #ffffff;
  box-shadow: 0 4px 4px 2px rgba($color: #000000, $alpha: 0.45);
  display: inline-flex;
  flex-direction: column;
  width: 100%;
}

.position-relative {
  position: relative;
}

.local-loading-bg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba($color: #000000, $alpha: 0.55);
  z-index: 50;
  border-radius: 4px;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #cef;
  border-color: #cef transparent #cef transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.filter {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #fa8c16;
  color: #fa8c16;
  background-color: #ffffff;
  padding: 0.375rem 0.375rem 0.375rem 1rem;
  box-sizing: border-box;
  height: 2rem;
  border-radius: 1rem;
  cursor: pointer;
  position: relative;
  transition: ease 0.25s;
}

.filter::after {
  font-family: "Material Icons Round";
  content: "arrow_drop_down";
  font-size: 1.5rem;
  transition: ease 0.25s;
}

.filter.active {
  background-color: #ffe7ba;
  box-shadow: 0 0 0 2px rgba($color: #fa8c16, $alpha: 0.35);
  z-index: 150;
}

.filter.active::after {
  transform: rotate(180deg);
}

.filter.selected {
  color: #ffffff;
  background-color: #fa8c16;
}

.filter > .filter-icon {
  margin-right: 0.25rem;
  width: 1rem;
  height: 1rem;
  font-size: 1.25rem;
}

.filter > .filter-number {
  margin-right: 0.25rem;
  width: 1rem;
  height: 1rem;
  color: #ffffff;
  background-color: #fa8c16;
  border-radius: 2rem;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
}

.filter.selected > .filter-number {
  background-color: #ffffff;
  color: #fa8c16;
}

.filter-body {
  position: absolute;
  top: 2rem;
  background-color: #ffffff;
  left: 0;
  border-radius: 0.25rem;
  box-shadow: 0px 2px 1px 1px rgba(0, 0, 0, 0.5);
  cursor: auto;
  color: #404040;
  overflow: hidden;
}

.filter::before {
  position: absolute;
  content: "";
  width: 1rem;
  height: 1rem;
  transform: rotate(-45deg);
  background-color: #ffffff;
  top: 1.75rem;
  left: 1.5rem;
  z-index: -1;
  box-shadow: 1px 0px 1px 1px rgba(0, 0, 0, 0.25);
}

.filter-inner-body {
  display: inline-flex;
  flex-direction: column;
  width: 18.75rem;
  padding: 1.5rem;
}

.filter-box {
  display: inline-flex;
  flex-direction: column;
  overflow-y: scroll;
}

.filter-box::-webkit-scrollbar {
  display: block;
  position: absolute;
  width: 3px;
}

.filter-box::-webkit-scrollbar-thumb {
  background-color: #ffd591;
  border-radius: 32px;
}

.filter-box::-webkit-scrollbar-thumb:hover {
  background-color: rgba($color: #ffd591, $alpha: 0.85);
}

.page-icon {
  font-size: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  color: rgba($color: #000000, $alpha: 0.65);
}

.page-icon.disabled {
  color: rgba($color: #000000, $alpha: 0.1);
  pointer-events: none;
}

.required::after {
  content: "*";
}

app-redeem-question,
app-user-profile {
  display: inline-flex;
  flex-direction: column;
}

.sort-icon {
  height: 0.5rem;
  cursor: pointer;
}

.border-bottom {
  border-bottom: 1px solid #e8e8e8;
}

app-volunteer-score-popup,
app-donation-user,
app-donation-center,
app-donation-overview {
  display: inline-flex;
  flex-direction: row;
}

app-event-preview,
app-request-preview,
app-overview-page,
app-user-ranking-analysis,
app-service-ratio-analysis,
app-inactive-user-analysis,
app-cell-group-data-analysis,
app-chart-analysis,
app-success-rate-analysis,
app-application-analysis,
app-balance-analysis,
app-analysis-page,
app-user-request-transaction,
app-user-event-transaction,
app-user-donation-transaction,
app-user-redeem-transaction,
app-user-transaction,
app-request-trash-bin,
app-event-trash-bin,
app-user-trash-bin,
app-donation-trash-bin,
app-redeem-trash-bin,
app-request-rating,
app-request-detail-volunteer-table,
app-event-day-scheduler,
app-request-create,
app-event-create,
app-event-overview,
app-request-overview {
  display: inline-flex;
  flex-direction: column;
}

app-event-detail-accepted-user-tab,
app-event-detail-confirmed-user-tab,
app-event-detail-pending-user-tab,
app-event-detail-rejected-user-tab {
  display: inline-flex;
  flex-direction: column;
  flex: 1;
}

.cdk-virtual-scroll-content-wrapper {
  display: inline-flex;
  flex-direction: column;
}

.mat-snack-bar-container {
  background-color: #ffffff;
  color: #000000;
}

.mat-simple-snackbar-action {
  color: #1890ff;
}
