.user-table {
  display: inline-flex;
  flex-direction: column;
  box-sizing: border-box;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  overflow-y: scroll;
}

.user-table-row {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}

.user-table-row.header {
  background-color: #fff7e6;
}

.user-table-cell {
  height: 2.25rem;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #bfbfbf;
  border-bottom: 1px solid #bfbfbf;
  overflow: hidden;
  flex-shrink: 0;
}

.user-table-cell > div.inline-flex-column {
  width: 1rem;
}

.user-table-row.header:first-child > .user-table-cell {
  border-top: 1px solid #bfbfbf;
}

.user-table-row > .user-table-cell:first-child {
  border-left: 1px solid #bfbfbf;
}

.user-table-row.header:first-child > .user-table-cell:first-child {
  border-top-left-radius: 0.5rem;
}

.user-table-row.header:first-child > .user-table-cell:last-child {
  border-top-right-radius: 0.5rem;
}

.user-table-cell.cbox {
  flex: 0 0 2rem;
}

.user-table-cell.donation-cbox {
  flex: 0 0 2.75rem;
}

.user-table-cell.member-id {
  flex: 0 0 8.25rem;
}

.user-table-cell.id,
.user-table-cell.name,
.user-table-cell.center,
.user-table-cell.credit {
  flex: 0 0 5.5rem;
}

.user-table-cell.gender,
.user-table-cell.age {
  flex: 0 0 4.125rem;
}

.user-table-cell.chineseName {
  flex: 0 0 6rem;
}

.user-table-cell.donation-date {
  flex: 0 0 6.5rem;
}

.user-table-cell.transaction {
  flex: 0 0 8.4375rem;
}

.user-table-cell.donation-type,
.user-table-cell.donation-memberId {
  flex: 0 0 9.1875rem;
}

.user-table-cell.phone,
.user-table-cell.identity {
  flex: 0 0 6.875rem;
}

.user-table-cell.operation {
  flex: 1;
}

.user-table-cell:last-child {
  flex: 1;
}

.user-table-scroll-box {
  display: inline-flex;
  flex-direction: column;
  overflow-y: overlay;
  position: relative;
}

.user-table-scroll-box::-webkit-scrollbar {
  display: block;
  position: absolute;
  width: 3px;
}

.user-table-scroll-box::-webkit-scrollbar-thumb {
  background-color: #979797;
  border-radius: 32px;
}

.user-table-scroll-box::-webkit-scrollbar-thumb:hover {
  background-color: rgba($color: #979797, $alpha: 0.85);
}

.user-table-cell.editing {
  border-color: rgb(254, 169, 64);
  border: 2px solid rgb(254, 169, 64);
  margin-left: -1px;
  margin-top: -1px;
  height: 2.3125rem;
}

.user-table-cell.editing > input {
  height: 100%;
  width: 100%;
  border: none;
  text-align: center;
}

.user-table-cell.editing.invalid {
  border-color: rgba($color: #f16371, $alpha: 0.75);
}

.list-table {
  display: inline-flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: scroll;
  min-width: 60rem;
}

.list-table-row {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  transition: ease 0.25s;
}

.list-table-row:hover {
  background-color: rgba($color: #000000, $alpha: 0.05);
}

.list-table-row.header {
  background-color: #fff7e6;
}

.list-table-cell {
  height: 3.5rem;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #bfbfbf;
  overflow: hidden;
  padding-left: 0.5rem;
  flex-shrink: 0;
}

.list-table-row.header > .list-table-cell {
  height: 2.25rem;
}

.list-table-row.header > .list-table-cell {
  border-bottom: none;
}

.list-table-row.header:first-child > .list-table-cell {
  border-top: 1px solid #bfbfbf;
}

.list-table-row > .list-table-cell:first-child {
  border-left: 1px solid #bfbfbf;
  padding-left: 0;
}

.list-table-cell > p {
  flex: 1;
  height: 1.875rem;
  flex-direction: row;
  align-items: center;
  line-height: 1.875rem;
}

.list-table-cell > p:last-child {
  border-right: 1px solid #bfbfbf;
}

.list-table-cell:last-child > p:last-child {
  border-right: none;
}

.list-table-cell > div.inline-flex-column {
  width: 1rem;
  align-items: center;
}

.list-table-cell:last-child > *,
.list-table-row.header > .list-table-cell > * {
  border-right: none;
}

.list-table-cell:last-child {
  flex: 1;
  border-right: 1px solid #bfbfbf;
}

.list-table-row:last-child > .list-table-cell {
  border-bottom: 1px solid #bfbfbf;
}

.list-table-cell.cbox {
  flex: 0 0 2rem;
}

.list-table-cell.redeem-close-date {
  flex: 0 0 6rem;
}

.list-table-cell.member-id {
  flex: 0 0 5.5rem;
}

.list-table-cell.redeem-start-date,
.list-table-cell.redeem-id,
.list-table-cell.name,
.list-table-cell.id,
.list-table-cell.credit,
.list-table-cell.announce-center,
.list-table-cell.staff-id,
.list-table-cell.created-by {
  flex: 0 0 5.5rem;
}

.list-table-cell.announce-date {
  flex: 0 0 7.5rem;
}

.list-table-cell.redeem-stock,
.list-table-cell.redeem-type,
.list-table-cell.center,
.list-table-cell.gender,
.list-table-cell.age {
  flex: 0 0 4.5rem;
}

.list-table-cell.phone {
  flex: 0 0 7rem;
}

.list-table-cell.redeem-status,
.list-table-cell.announce-status {
  flex: 0 0 8.5rem;
}

.list-table-cell.identity {
  flex: 0 0 6.875rem;
}

.list-table-cell.redeem-title {
  flex: 0 0 12.5rem;
}

.list-table-cell.announce-title {
  flex: 0 0 13.5rem;
}

.list-table-cell.address {
  flex: 0 0 16.5rem;
}

.list-table-cell.time {
  flex: 0 0 4.5rem;
}

.list-table-cell.center-name {
  flex: 0 0 20.5rem;
}

.agent-table {
  display: inline-flex;
  flex-direction: column;
  width: 40.8125rem;
}

.agent-table-row {
  border-bottom: 1px solid #e9e9e9;
  height: 2.5rem;
  flex: 0 0 2.5rem;
  display: inline-flex;
  flex-direction: row;
}

.agent-table-row.header {
  height: 2.25rem;
  flex: 0 0 2.25rem;
  border-top: 1px solid #e9e9e9;
}

.agent-table-row.header > .agent-table-cell {
  color: rgba($color: #000000, $alpha: 0.5) !important;
}

.agent-table-cell {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  flex: 2 0;
  justify-content: center;
  color: #000000;
}

.agent-table-cell.remark {
  flex: 3 0;
  justify-content: flex-start;
  margin-left: 2.5rem;
}

.list-table::-webkit-scrollbar {
  display: none;
}
