.text-xs {
  font-size: 0.625rem;
}

.text-sm {
  font-size: 0.75rem !important;
}

.text-lg {
  font-size: 1rem !important;
}

.text-xl {
  font-size: 1.125rem !important;
  line-height: 1.125rem;
}

.text-xxl {
  font-size: 1.25rem;
  line-height: 1.25rem;
}

.text-xxxl {
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.text-green{
  color: #00A200;
}

.text-red {
  color: rgba($color: #dc3545, $alpha: 1);
}

.text-grey {
  color: rgba($color: #000000, $alpha: 0.65);
}

.text-lightgrey {
  color: rgba($color: #000000, $alpha: 0.45);
}

.text-yellow,
.text-orange {
  color: #fa8c16;
}

.text-blue {
  color: #0050b3;
}

.text-lightblue {
  color: #1890ff;
}

.text-bold {
  font-weight: 600;
}

.click-text-lightblue {
  color: #1890ff !important;
  cursor: pointer !important;
}

.click-text-blue {
  color: #0050b3;
  cursor: pointer;
}

.click-text-blue.disabled {
  color: #919191 !important;
  cursor: default;
}

.click-text-blue:hover {
  color: rgba($color: #0050b3, $alpha: 0.85);
}

.click-text-blue:active {
  color: #0050b3;
}

.click-text-blue::selection {
  background-color: transparent;
}

.text-center {
  text-align: center;
}

.lh-8{
  line-height: 2rem;
}

p.disabled{
  color: #A8A8A9!important;
  pointer-events: none;
}