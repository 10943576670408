.popup-bg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.55);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 100;
}
