.add-icon {
  color: #ffa940;
  cursor: pointer;
  font-size: 1.25rem !important;
}

.star {
  color: #ffa940;
}

.info-icon {
  width: 0.75rem;
  height: 1rem;
  font-size: 0.75rem !important;
  color: #1890ff;
  line-height: 1rem;
}

.close-icon {
  font-size: 1.25rem !important;
  cursor: pointer;
  width: 1rem;
}
