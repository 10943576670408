$p1: 0.125rem;
$p2: 0.25rem;
$p3: 0.375rem;
$p4: 0.5rem;
$p5: 0.625rem;
$p6: 0.75rem;
$p7: 0.875rem;
$p8: 1rem;
$p9: 1.125rem;

.pl-2 {
  padding-left: $p2 !important;
}
