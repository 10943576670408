input.user-input {
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 4px;
  height: 2rem;
  font-size: 0.875rem;
  padding: 0.3125rem 0.75rem;
  transition: ease 0.25s;
}

input.user-input.xxxs {
  width: 3.6875rem;
  padding: 0.5rem 0.4375rem;
}

input.user-input.xxs {
  width: 4.5rem;
  padding: 0.5rem 0.4375rem;
}

input.user-input.remark {
  width: 6.3125rem;
  padding: 0.3125rem 0.6875rem;
}

input.user-input.xs {
  width: 7rem;
}

input.user-input.sm {
  width: 7.375rem;
}

input.user-input.md {
  width: 10rem;
}

input.user-input.lg {
  width: 10.75rem;
}

input.user-input.xl {
  width: 14.5rem;
}

.user-input::placeholder {
  color: #bfbfbf;
}

.user-input:focus {
  border-color: #1890ff;
  box-shadow: 0 0 0 2px rgba($color: #1890ff, $alpha: 0.35);
}

textarea.ng-touched.ng-invalid,
.user-input.ng-touched.ng-invalid,
.user-input.ng-touched.invalid {
  border: 1px solid #f5222d !important;
  // background-color: rgba($color: #f5222d, $alpha: 0.1);
}

textarea.ng-touched.ng-invalid:focus,
.user-input.ng-touched.ng-invalid:focus,
.user-input.ng-touched.invalid:focus {
  box-shadow: 0 0 0 2px rgba($color: #f5222d, $alpha: 0.35) !important;
}

input.user-input.ng-touched.invalid:disabled {
  border-color: #dddddd !important;
}

input.user-input:disabled {
  background-color: #e9e9e9;
}

input[type="checkbox"] {
  width: 1rem;
  height: 1rem;
  margin: 0;
  flex: 0 0 auto;
}

input[type="radio"] {
  width: 1rem;
  height: 1rem;
  margin: 0 0.25rem 0 0;
}

label.checkbox {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.page-input {
  width: 3.0625rem;
  height: 1.5rem;
  box-sizing: border-box;
  border: 1px solid rgba($color: #000000, $alpha: 0.15);
  border-radius: 0.25rem;
  text-align: center;
  font-size: 0.75rem;
  color: rgba($color: #000000, $alpha: 0.65);
  transition: ease 0.25s;
}

.page-input:focus {
  border-color: #1890ff;
  box-shadow: 0 0 0 2px rgba($color: #1890ff, $alpha: 0.35);
}

.page-number {
  width: 2.1875rem;
  height: 1.5rem;
  box-sizing: border-box;
  font-size: 0.75rem;
  color: rgba($color: #000000, $alpha: 0.65);
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.user-input-error {
  position: absolute;
  color: #dc3545 !important;
}

.user-input-error > p {
  font-size: 0.625rem;
  line-height: 0.75rem;
}

textarea {
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: rgba($color: #000000, $alpha: 0.65);
  border-radius: 0.25rem;
  transition: 0.25s ease;
  padding: 0.3125rem 0.75rem;
  box-sizing: border-box;
}

textarea:focus {
  border-color: #1890ff;
  box-shadow: 0 0 0 2px rgba($color: #1890ff, $alpha: 0.35);
}

.time-input {
  width: 4rem;
  height: 2rem;
  text-align: center;
}
