div.user-select {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  background-color: #ffffff;
}

div.user-select::after {
  content: "expand_more";
  position: absolute;
  color: #bfbfbf;
  font-size: 1rem;
  font-family: "Material Icons Round";
  right: 0.5rem;
  transition: ease 0.25s;
  z-index: 0;
}

div.user-select:focus-within::after {
  transform: rotate(180deg);
}

select.user-select {
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 4px;
  height: 2rem;
  font-size: 0.875rem;
  padding: 0.3125rem 1.5rem 0.3125rem 0.75rem;
  transition: ease 0.25s;
  z-index: 1;
  background-color: transparent;
}

.user-select:focus {
  border-color: #1890ff;
  box-shadow: 0 0 0 2px rgba($color: #1890ff, $alpha: 0.35);
}

.user-select.ng-invalid {
  color: #bfbfbf !important;
  // background-color: rgba($color: #f5222d, $alpha: 0.1);
}

.user-select.ng-touched.ng-invalid {
  border: 1px solid #f5222d !important;
  color: #bfbfbf;
  // background-color: rgba($color: #f5222d, $alpha: 0.1);
}

.user-select.ng-touched.ng-invalid {
  border: 1px solid #f5222d !important;
  color: #bfbfbf !important;
  // background-color: rgba($color: #f5222d, $alpha: 0.1);
}

.user-select.ng-touched.ng-invalid:focus {
  box-shadow: 0 0 0 2px rgba($color: #f5222d, $alpha: 0.35) !important;
}

select.user-select.xs {
  width: 7rem;
}

select.user-select.sm{
  width: 7.375rem;
}

select.user-select.lg {
  width: 10.75rem;
}

select.user-select.xl{
  width: 11.625rem;
}

select.user-select.xxxl{
  width: 14.5rem;
}

select.user-select:disabled{
  color: rgba($color: #000000, $alpha: 0.25);
  background-color: #e9e9e9;
}

select:disabled{
  opacity: 1;
}

div.pagination {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  background-color: #ffffff;
}

div.pagination::after {
  content: "expand_more";
  position: absolute;
  color: #bfbfbf;
  font-size: 1rem;
  font-family: "Material Icons Round";
  right: 0.5rem;
  transition: ease 0.25s;
  z-index: 0;
}

div.pagination:focus-within::after {
  transform: rotate(180deg);
}

select.pagination {
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 0.75rem;
  transition: ease 0.25s;
  z-index: 1;
  background-color: transparent;
  width: 6.25rem;
  height: 1.5rem;
  padding-left: 0.75rem;
  color: rgba($color: #000000, $alpha: 0.65);
}

.pagination:focus {
  border-color: #1890ff;
  box-shadow: 0 0 0 2px rgba($color: #1890ff, $alpha: 0.35);
}
